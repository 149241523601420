import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';
     
@Component({
  selector: 'app-root',
  template: `
    <div *ngIf="auth.user | async as user; else showLogin">
      <h4>Hello {{ user.displayName }} !</h4>
      <table border=1>
      <tr valid="middle"><img src="{{ user.photoURL }}" alt="Avatar" width="64" height="64"> 
      <td>E-mail: {{ user.email }} <br>
      ID: {{ user.uid }} <br>
      Init: {{ user.metadata.creationTime }} <br>
      Last: {{ user.metadata.lastSignInTime }}
      </table>
      <hr>
      <button (click)="logout()">Logout</button>
    </div>
    <ng-template #showLogin>
      <p>Welcome!
      <button (click)="login()">Please, login with Google</button></p>
    </ng-template>
  `,
})
export class AppComponent {
  title = 'ivy';
  constructor(public auth: AngularFireAuth) {
  }
  login() {
    this.auth.signInWithPopup(new auth.GoogleAuthProvider());
  }
  logout() {
    this.auth.signOut();
  }
}


//@Component({
  // selector: 'app-root',
  // templateUrl: './app.component.html',
  // styleUrls: ['./app.component.css']
//})
//export class AppComponent {
//  title = 'ivy-com';
//}
